<template>
  <form @submit.prevent="formSubmit">
    <!-- Form -->
    <div class="form-group mb-3">
      <div class="input-group">
        <span
          class="input-group-text"
          id="basic-addon1"
          :class="{ 'is-invalid': $v.user.username.$error }"
        >
          <span class="fas fa-user"></span>
        </span>
        <input
          type="number"
          class="form-control"
          placeholder="Nro Documento"
          v-model.trim="$v.user.username.$model"
          :class="{ 'is-invalid': $v.user.username.$error }"
        />
      </div>
    </div>
    <!-- End of Form -->
    <div class="d-grid gap-2 text-center mt-3">
      <template v-if="!loading">
        <button type="submit" class="btn btn-custom2">RECUPERAR</button>
      </template>
      <template v-else>
        <button class="btn btn-custom2 disabled"
          ><i class="fa fa-spinner fa-spin"></i> Cargando</button
        >
      </template>
      <router-link :to="{ name: 'login' }" class="btn btn-custom"
        >REGRESAR AL LOGIN</router-link
      >
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { successRequest, errorRequest } from '@helpers'

export default {
  name: 'ForgotPasswordForm',
  data: function () {
    return {
      user: {
        username: '',
      },
      loading: false,
    }
  },
  validations: {
    user: {
      username: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
    }),
  },
  created() {
    if (this.loggedIn) this.$router.push({ name: 'loading' })
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),
    async formSubmit() {
      this.$v.$touch()
      this.loading = true
      if (!this.$v.$invalid) {
        await this.resetPassword(this.user).then(
          (data) => {
            this.loading = false
            return successRequest(data, this.$swal)
          },
          (error) => {
            this.loading = false
            return errorRequest(error, this.$swal)
          }
        )
      }
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
